.nav-index {
z-index: 999;
width: 250px;
height: 100VH;
}

.logo {
width: 100%;
max-height:100%
}

.logo-small {
height: 24px;
}

.level-top {
height: 60px;
}

.pointer {
cursor: pointer;
}

.inner {
min-height: 80VH;
}

.put-margin {
margin-left: 250px;
}

.head-fix{
z-index:99;
position: -webkit-sticky;
position: sticky;
top: 0;
}

.nav-fix{
position: -webkit-sticky;
position: sticky;
background: transparent;
top: 92VH;
}

.right-fix{
position: -webkit-sticky;
position: sticky;
top: 70px;
}

.marmasco-color{
color: #A8518A;
} 
.marmasco-scroll-up {
z-index:99;
position: -webkit-fixed;
position: fixed;
bottom: 5px;
}

.foot {
padding: 24px;
}

.foot a {
text-decoration: none;
}

.unlinkify {
text-decoration: none;
}

.cls-1 {
  fill: grey;
}
.cls-2 {
  fill: grey;
}
.cls-3 {
  fill: grey;
}

#stripe {
  animation: stripe-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes stripe-anim {
  25% {
    transform: translate(10px, 0) rotate(-10deg)
  }
  75% {
    transform: translateX(10px)
  }
}
.circle {
  animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  animation-duration: 2s
}

.circle.c2 {
  animation-duration: 3s
}

.circle.c3 {
  animation-duration: 1s
}

.circle.c4 {
  animation-duration: 1s
}

.circle.c5 {
  animation-duration: 2s
}

.circle.c6 {
  animation-duration: 3s
}

@keyframes circle-anim {
  50% {
    transform: scale(.2) rotateX(360deg) rotateY(360deg)
  }
}

.four,
#ou {
  animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes four-anim {
  50% {
    transform: scale(.98)
  }
}

.put-center{
width: 100%;
}

.put-center-data{
border-radius: 20px;
}

@media (min-width:601px){
.put-center{
min-height: 80VH;
position: relative;
}
.put-center-data{
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
position: absolute;
max-width: 600px;
}}

.nav-item-sign {
  font-size: 20px;
}

.nav-item-header {
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border: none;
  background-color:transparent;
  text-decoration: none;
}

.nav-item-header:hover{
  background-color: #009688;
  color: #fff;
}

.accordian-body {
  padding: 10px 5px;
}

.marmasco-scrollinfo::-webkit-scrollbar{width:12px}.marmasco-scrollinfo::-webkit-scrollbar-track{background:#00968808}.marmasco-scrollinfo::-webkit-scrollbar-thumb{background-color:#00968808;border-radius:20px;border:3px solid #eae5e5}.marmasco-scrollinfo{scrollbar-width:thin;scrollbar-color:#eae5e5 #00968808}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li:last-child {
  border-right: none;
}

li span {
  display: block;
  cursor: pointer;
  color: black;
  text-align: center;
  padding: 18px 12px;
  text-decoration: none;
}

li span:hover{
  background-color: #009688;
  color: #fff;
}

li item {
  display: block;
  cursor: pointer;
  color: #009688;
  text-align: center;
  padding: 18px 12px;
  text-decoration: none;
}

li item:hover{
  background-color: #009688;
  color: #fff;
}
